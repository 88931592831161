import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../models/Api";

const Articles = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    Api.getArticles().then(data => {
      if (data.data.ok) {
        setArticles(data.data.data.data);
      } else {
        alert(data.data.data.error);
      }
    });
  }, []);

  const formatContent = content => {
    // Убираем <pre> и делим по двойным переносам строк на абзацы
    return content
        .replace(/<\/?pre>/g, "") // Убираем теги <pre>
        .split(/<br>\s*<br>/) // Разделяем абзацы по двойным <br>
        .map(paragraph => paragraph.split(/<br>/)); // Разделяем строки внутри абзацев
  };

  return (
      <div className="w-full px-4 md:px-8 bg-zinc-200 py-28">
        {articles.length === 0 && (
            <div className="m-auto bg-zinc-100 p-4 border border-slate-300 rounded-xl shadow-xl mb-3">
              <div className="text-center text-2xl">
                Следите за нашими новостями на этой странице
              </div>
              <p className="pt-4 text-lg text-center">
                Скоро тут появятся обновления :)
              </p>
            </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map(a => (
              <div
                  key={a.id}
                  className="bg-zinc-100 p-4 border border-slate-300 rounded-xl shadow-lg cursor-pointer transition-transform transform hover:scale-105"
                  onClick={() => navigate("/article/" + a.id)}
              >
                <div className="text-xl font-semibold mb-2">{a.title}</div>
                {a.image && (
                    <img
                        src={a.image}
                        alt=""
                        className="w-full h-40 object-cover rounded-md mb-2"
                    />
                )}
                <p className="text-sm text-gray-700">
                  {a.content.length > 100 ? formatContent(a.content.substring(0, 100)) + "..." : formatContent(a.content)}
                </p>
              </div>
          ))}
        </div>
      </div>
  );
};

export default Articles;
