import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../models/Api";
import styles from "./Article.module.css";

const Article = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    Api.getArticle(articleId).then(data => {
      if (data.data.ok) {
        // Обрабатываем контент для удобного отображения
        const formattedContent = formatContent(data.data.data.content);
        setArticle({ ...data.data.data, content: formattedContent });
      } else {
        alert(data.data.error);
      }
    });
  }, [articleId]);

  // Функция для форматирования контента
  const formatContent = content => {
    // Убираем <pre> и делим по двойным переносам строк на абзацы
    return content
        .replace(/<\/?pre>/g, "") // Убираем теги <pre>
        .split(/<br>\s*<br>/) // Разделяем абзацы по двойным <br>
        .map(paragraph => paragraph.split(/<br>/)); // Разделяем строки внутри абзацев
  };

  return (
      <div className="w-full px-4 md:px-8 bg-zinc-200 py-28">
        {article ? (
            <div className="m-auto bg-zinc-100 p-4 md:p-8 border border-slate-300 rounded-xl shadow-xl mb-3 max-w-screen-md">
              <h1 className="text-2xl mb-4">{article.title}</h1>

              {/* Рендерим форматированный контент */}
              <div className={styles.articleContent}>
                {article.content.map((paragraph, index) => (
                    <p key={index} className="mb-4 text-lg">
                      {paragraph.map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line}
                            <br />
                          </React.Fragment>
                      ))}
                    </p>
                ))}
              </div>
            </div>
        ) : (
            <div>Загрузка...</div>
        )}
      </div>
  );
};

export default Article;
