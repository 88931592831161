import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import dataService from '../services/dataService';
import Spinner from './Spinner';
import PurchaseForm from './PurchaseForm'; // Импортируем компонент формы покупки

const ServiceDetail = () => {
    const { id } = useParams();
    const [service, setService] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false); // Состояние для открытия/закрытия формы

    useEffect(() => {
        const fetchService = async () => {
            try {
                const data = dataService.getData();
                const serviceDetail = data.find((s) => s.service === id);
                setService(serviceDetail);
            } catch (error) {
                console.error('Ошибка при загрузке данных услуги:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchService();
    }, [id]);

    if (isLoading) {
        return <Spinner />;
    }

    if (!service) {
        return (
            <div className="flex flex-col justify-center items-center h-screen bg-[#f5f5f5]">
                <div className="text-[#333] text-[24px] leading-[32px] font-semibold mb-[20px]">
                    Услуга не найдена
                </div>
                <RouterLink to="/">
                    <button
                        className="w-full rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                    >
                        Вернуться на главную страницу
                    </button>
                </RouterLink>
            </div>
        );
    }

    const openForm = () => {
        setIsFormOpen(true);
    };

    const closeForm = () => {
        setIsFormOpen(false);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex flex-col min-w-full mt-[70px] px-16 pt-[40px] items-center ">
                    <div className="p-[20px] max-w-[1000px] w-full">
                        <img
                            className="w-full max-w-[200px] h-[200px] object-cover rounded-md mx-auto mb-[20px]"
                            src={dataService.getImage(service.category)}
                            alt={service.whitelist.new_name}
                        />
                        <div className="text-center">
                            <h1 className="text-[22px] font-semibold text-[#333] mb-[10px]">
                                {service.whitelist.new_name}
                            </h1>
                            <p className="text-[16px] text-[#666] mb-[15px]">
                                {service.whitelist.content}
                            </p>
                            <p>
                                Только реальные пользователи. Без ботов. <br/>
                                Русскоязычные пользователи. Рекламный метод привлечения.<br/>
                                Нам НЕ нужен доступ к Вашим аккаунтам, Ваша личная информация и коды из SMS.<br/>
                            </p>
                            <p className="text-[20px] text-[#007bff] font-semibold">
                                Цена: {Math.trunc(service.whitelist.new_rate * 100) / 100} руб. за 1000
                            </p>
                            <button
                                className="w-full max-w-[300px] mt-[20px] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold bg-indigo-600"
                                onClick={openForm}
                            >
                                Купить
                            </button>
                        </div>
                    </div>
                    {isFormOpen && <PurchaseForm item={service} onClose={closeForm} />}
            </div>
        </div>
    );
};

export default ServiceDetail;
