import React, { useState } from 'react';
import Api from "../models/Api";
import dataService from "../services/dataService";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import Spinner from "./Spinner";
import { Link as RouterLink } from "react-router-dom";


const PurchaseForm = ({ item, onClose }) => {
    const [formData, setFormData] = useState({});
    const [formDataInfo, setFormDataInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [schema, setSchema] = useState(dataService.getFormData(item.type));
    const [isFormOpenInfo, setIsOpenFormInfo] = useState(item.type !== "Subscriptions");

    const isValidURL = (url) => /^(https?:\/\/)?\w+(\.\w+)+[/#?]?.*$/.test(url);
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isAmountMTOne = (amount) => amount > 1;

    const onFormChange = (e) => {
        let newFormDataInfo = { price: 0 };
        if (e.formData.isLaunch && e.formData.runs) {
            e.formData.total = String(Number(e.formData.quantity) * Number(e.formData.runs));
            if (item.max && Number(item.max) < Number(e.formData.total)) {
                e.formData.total = `Количество больше максимального (${item.max})`;
            }
            let calcPrice = (Number(item.whitelist.new_rate) / 1000) * Number(e.formData.quantity) * Number(e.formData.runs);
            newFormDataInfo.price = parseFloat(calcPrice).toFixed(2);
            e.formData.price = calcPrice;
        } else {
            let calcPrice = (Number(item.whitelist.new_rate) / 1000) * Number(e.formData.quantity);
            newFormDataInfo.price = parseFloat(calcPrice).toFixed(2);
            e.formData.price = calcPrice;
        }
        setFormData(e.formData);
        setFormDataInfo(newFormDataInfo);
    };

    const onFormSubmit = (event) => {
        if (!isAmountMTOne(event.formData.price)) {
            alert("Минимальная сумма оплаты - 1 рубль");
            return;
        }

        if (!isValidEmail(event.formData.email)) {
            alert(`Проверьте правильность почтового адреса! (${event.formData.email})`);
            return;
        }

        if (!isValidURL(event.formData.link)) {
            alert(`Проверьте правильность ссылки! (${event.formData.link})`);
            return;
        }

        setIsLoading(true);

        if (event.formData.expiry) {
            let expiry = event.formData.expiry.split("-");
            event.formData.expiry = `${expiry[2]}/${expiry[1]}/${expiry[0]}`;
        }

        if (!event.formData.isLaunch) {
            delete event.formData.runs;
            delete event.formData.interval;
        }

        Api.servicePay(event.formData.email, item.panel_id, item.service, event.formData)
            .then((data) => {
                const link = data?.data?.data?.link;
                if (link) {
                    window.open(link, "_blank");
                    onClose();
                } else {
                    alert("Ошибка оплаты, пожалуйста обратитесь в поддержку сайта");
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div style={{ zIndex: 1000 }} className="fixed flex w-100 h-100 items-center justify-center">
            <div className="flex flex-col px-[20px] mx-auto m-0 p-50 z-50 bg-white p-5 border-black border-2 rounded-2xl mt-[20px] mb-[25px] pl-[50px] pr-[50px]">
                {schema && (
                    <div className="drop-shadow-lg">
                        <Form
                            onChange={onFormChange}
                            formData={formData}
                            schema={schema}
                            validator={validator}
                            onSubmit={onFormSubmit}
                        >
                            {isFormOpenInfo && (
                                <Form
                                    formData={formDataInfo}
                                    schema={dataService.getFormData("Price")}
                                    validator={validator}
                                    children={true}
                                />
                            )}
                            <div>
                                <button
                                    className="mt-[25px] p-2 bg-indigo-600 rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    <div className="flex flex-row">
                                        <div className="pr-1">Оплатить</div>
                                        {isLoading && <Spinner />}
                                    </div>
                                </button>
                                <button
                                    className="mt-[25px] ml-2 p-2 bg-indigo-600 rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                                    onClick={onClose}
                                >
                                    Отмена
                                </button>
                            </div>
                            <div className="pt-2">
                                <p className="text-sm">
                                    Подтверждая заказ, Вы соглашаетесь с условиями{" "}
                                    <RouterLink className="no-underline" to="/oferta">
                                        пользовательского соглашения
                                    </RouterLink>
                                    .
                                </p>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PurchaseForm;
